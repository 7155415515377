/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import React from 'react';
import { GoogleOAuthProvider } from "@react-oauth/google";

export const wrapRootElement = ({ element }) => (
    <GoogleOAuthProvider clientId='711931474038-9geatiu873vmgsdqas4862hrm2qgenah.apps.googleusercontent.com'>
      {element}
    </GoogleOAuthProvider>
  );
  